<template>
  <v-container fluid>
    <v-card flat>
      <v-row no-gutters class="pt-2">
        <v-col cols="5">
          <v-autocomplete
            v-model="processId"
            outlined
            dense
            :items="processes"
            :loading="isLoadingProcesses"
            :search-input.sync="searchProcesses"
            hide-no-data
            clearable
            item-text="name"
            item-value="id"
            class="pl-2"
            :label="$lang.labels.process"
            :placeholder="$lang.actions.startTyping"
            prepend-inner-icon="mdi-cloud-search-outline"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.name }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2" class="pl-2">
          <v-datetime-picker
            v-model="dateFrom"
            :label="$lang.labels.from"
            no-title
            :text-field-props="{ outlined: true, dense: true }"
          >
            <template v-slot:dateIcon="{}">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon="{}">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col cols="2" class="pl-2">
          <v-datetime-picker
            v-model="dateTill"
            :label="$lang.labels.to"
            no-title
            :text-field-props="{ outlined: true, dense: true }"
          >
            <template v-slot:dateIcon="{}">
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon="{}">
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col cols="2">
          <v-btn
            text
            color="primary"
            class="ml-2"
            @click="sendQuery()"
          ><v-icon>mdi-refresh</v-icon></v-btn>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="status"
            hide-selected
            outlined
            dense
            clearable
            :items="formattedStatuses"
            hide-no-data
            class="pl-2"
            :label="processes && processes.length > 0 ? $lang.labels.status : $lang.labels.selectProcessFirst"
            :placeholder="processes && processes.length > 0 ? $lang.actions.startTyping : $lang.labels.selectProcessFirst"
            :disabled="!processes || processes.length === 0"
            prepend-inner-icon="mdi-format-list-bulleted"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="triggerId"
            hide-selected
            outlined
            dense
            clearable
            :search-input.sync="searchTriggerId"
            :items="formattedTriggers"
            hide-no-data
            class="pl-2"
            :label="processes && processes.length > 0 ? $lang.labels.triggerId : $lang.labels.selectProcessFirst"
            :placeholder="processes && processes.length > 0 ? $lang.actions.startTyping : $lang.labels.selectProcessFirst"
            :disabled="!processes || processes.length === 0 || formattedTriggers.length === 0"
            prepend-inner-icon="mdi-format-list-bulleted"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="type"
            hide-selected
            outlined
            dense
            clearable
            :items="formattedTypes"
            hide-no-data
            class="pl-2"
            :label="processes && processes.length > 0 ? $lang.labels.type : $lang.labels.selectProcessFirst"
            :placeholder="processes && processes.length > 0 ? $lang.actions.startTyping : $lang.labels.selectProcessFirst"
            :disabled="!processes || processes.length === 0"
            prepend-inner-icon="mdi-format-list-bulleted"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="px-2">
          <v-alert dark color="secondary" :data-cy="$lang.hints.eventPermission">{{ $lang.hints.eventPermission }}</v-alert>
        </v-col>
      </v-row>
    </v-card>
    <v-tabs
      v-model="tab"
    >
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab
        :key="0"
        data-cy="tab-details"
      >
        {{ $lang.labels.logs }}
      </v-tab>
      <v-tab
        :key="1"
        data-cy="tab-permissions"
      >
        {{ $lang.labels.diagrams }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="fill-height">
      <v-tab-item
        :key="0"
        class="fill-height"
      >
        <v-row dense no-gutters>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items.items"
              item-key="id"
              class="elevation-1"
              :loading="loading"
              :options.sync="options"
              :server-items-length="items.meta.totalItems"
              :footer-props="{
                'items-per-page-options': rowsPerPageItemsGlobal
              }"
            >
              <template v-slot:top>

              </template>

              <template v-slot:item.id="{ item }">
                <div class="font-weight-bold"># {{ item.id }}</div>
              </template>

              <template v-slot:item.triggerId="{ item }">
                <div class="d-inline-flex">
                  <router-link v-if="item.triggerId" class="clickable" :to="{ name: item.type === 'CRON' ? 'cronEdit' : 'restEdit', params: { id: item.triggerId } }">{{ item.triggerId }}</router-link>
                  <div v-if="item.triggerId" class="clickable ml-1" @click="searchTriggerId = String(item.triggerId); triggerId = String(item.triggerId)">{{ $lang.actions.filter }}</div>
                </div>
              </template>

              <template v-slot:item.createdOn="{ item }">
                <div>{{ item.createdOn | formatDateTimePrecise }}</div>
              </template>

              <template v-slot:item.processingStartOn="{ item }">
                <div>{{ item.processingStartOn | formatDateTimePrecise }}</div>
              </template>

              <template v-slot:item.modifiedOn="{ item }">
                <div>{{ item.modifiedOn | formatDateTimePrecise }}</div>
              </template>

              <template v-slot:item.status="{ item }">
                <div>{{ $lang.status[item.status] }}</div>
              </template>

              <template v-slot:item.type="{ item }">
                <div>{{ $lang.status[item.type] }}</div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="d-inline-flex">
                  <v-btn color="primary" :loading="item.isLoading" class="mx-1 button-default-width" @click="openEventModal(item)">{{ $lang.labels.event }}</v-btn>
                  <v-btn
                    color="primary"
                    class="ml-1"
                    @click="goToLogsStep(item.processId, item.id, dateFrom, dateTill)"
                  >{{ $lang.labels.steps }}</v-btn>
                  <v-btn color="info" class="mx-1" @click="goToProcess(item)">{{ $lang.labels.debug }}</v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
        :key="1"
        class="fill-height"
      >
        <events-bar v-if="tab === 1" :options="optionsForChart" :total-items="items.meta.totalItems" />
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-if="showDetails" v-model="showDetails" max-width="71%" style="min-height: 80vh; max-height: 80vh">
      <log-cache
        :event-id="String(eventId)"
        :data="selectedEventData"
        @closeDialog="eventId = ''; selectedEventData = null; showDetails = false"
      ></log-cache>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess,
  getEventByIdUsingGET as getEventDetails
} from '@/utils/api'
import LogCache from '../../components/ui/modals/LogCacheEvent'
import EventsBar from '../../components/ui/charts/EventsBar'
import { mapState } from 'vuex'
import { bus } from '@/main'
import { format } from 'date-fns'

export default {
  components: {
    LogCache,
    EventsBar
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDetails: false,
      isLoadingProcesses: false,
      showTypeSelector: false,
      options: {},
      totalItems: 0,
      eventId: '',
      headers: [
        {
          text: this.$lang.labels.instanceId,
          align: 'start',
          sortable: true,
          value: 'instanceId'
        },
        { text: this.$lang.labels.createdOn, value: 'createdOn' },
        { text: this.$lang.labels.processingStartOn, value: 'processingStartOn' },
        { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' },
        { text: this.$lang.labels['threadId'], value: 'threadId' },
        { text: this.$lang.labels.triggerId, value: 'triggerId' },
        { text: this.$lang.labels.type, value: 'type' },
        { text: this.$lang.labels.status, value: 'status', sortable: false },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      processes: [],
      searchProcesses: '',
      dateToday: new Date(),
      dateFrom: new Date(),
      dateTill: new Date(Date.now() + ( 3600 * 1000 * 24)),
      processId: '',
      status: '',
      triggerId: '',
      searchTriggerId: '',
      type: '',
      lock: false,
      selectedEventData: null,
      searchEventId: null,
      statusesArr: ['FAILED', 'IN_PROGRESS', 'NEW', 'ON_HOLD', 'SUCCESSFUL', 'PROCESS_INACTIVE'].sort(),
      typeArr: ['CRON', 'REST', 'RUN_PROCESS', 'MESSAGING'].sort(),
      tab: 0,
      optionsForChart: {}
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'globalTheme', 'userSettings']),
    formattedStatuses () {
      return this.statusesArr.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedTypes () {
      return this.typeArr.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedTriggers () {
      return this.items && this.items.items && this.items.items.length > 0 ? this.items.items.map((x) => String(x.triggerId)).filter((n) => n !== 'null' && n !== '') : []
    },
    computedDateFromFormatted () {
      return this.$options.filters.formatDate(this.dateFrom)
    },
    computedDateTillFormatted () {
      return this.$options.filters.formatDate(this.dateTill)
    }
  },
  watch: {
    formattedTriggers: {
      handler(val) {
        if (!val || val.length === 0) this.triggerId = ''
      }
    },
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcessesFunction(val)
        }
      }
    },
    searchEventId: {
      handler(val) {
        if (!val) this.eventId = null
      }
    },
    options: {
      handler () {
        this.sendQuery(false)
      },
      deep: true
    },
    processId: {
      handler () {
        this.sendQuery()
      }
    },
    dateFrom: {
      handler () {
        this.sendQuery()
      }
    },
    dateTill: {
      handler () {
        this.sendQuery()
      }
    },
    status: {
      handler () {
        this.sendQuery()
      }
    },
    triggerId: {
      handler () {
        this.sendQuery()
      }
    },
    type: {
      handler () {
        this.sendQuery()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    this.dateFrom.setHours(0,0,0,0)
    this.dateTill.setHours(23,59,59,999)
    if (this.$route && this.$route.query && this.$route.query.processId && this.$route.query.dateFrom  && this.$route.query.dateTill) {
      this.lock = true
      this.eventId = this.$route.query.eventId ? this.$route.query.eventId : ''
      this.dateFrom = new Date(this.$route.query.dateFrom)
      this.dateFrom.setHours(0,0,0,0)
      this.dateTill = new Date(this.$route.query.dateTill)
      this.dateTill.setHours(23,59,59,999)
      this.processId = this.$route.query.processId
      if (this.$route.query.triggerId) this.triggerId = this.$route.query.triggerId
      this.loadPreFill()
    } else {
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (preFill && preFill.eventLogList) {
          this.lock = true
          this.eventId = preFill.eventLogList.eventId
          this.dateFrom = new Date(preFill.eventLogList.dateFrom)
          this.dateTill = new Date(preFill.eventLogList.dateTill)
          this.processId = preFill.eventLogList.processId
          this.status = preFill.eventLogList.status
          this.triggerId = preFill.eventLogList.triggerId
          this.type = preFill.eventLogList.type
          this.loadPreFill()
        }
      }
    }
  },
  methods: {
    openEventModal(item) {
      item.isLoading = true
      getEventDetails({ id: item.id })
        .then((res) => {
          item.isLoading = false
          this.eventId = res.data.data.id
          this.selectedEventData = res.data.data
          this.showDetails = true
        })
        .catch((err) => {
          item.isLoading = false
          this.err = err
        })
    },
    goToProcess(item) {
      this.$router.push({ name: 'processEdit', params: { id: item.processId }, query: { eventId: item.id } })
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.eventLogList) preFill.eventLogList = {
          dateFrom: '',
          dateTill: '',
          processId: '',
          eventId: '',
          status: '',
          triggerId: '',
          type: ''
        }

        preFill.eventLogList.dateFrom = this.dateFrom
        preFill.eventLogList.dateTill = this.dateTill
        preFill.eventLogList.processId = this.processId
        preFill.eventLogList.eventId = this.eventId
        preFill.eventLogList.status = this.status
        preFill.eventLogList.triggerId = this.triggerId
        preFill.eventLogList.type = this.type

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      this.isLoadingProcesses = true
      getProcess({
        id : this.processId
      })
        .then((res) => {
          this.processId = ''
          this.processes = [res.data.data]
          this.isLoadingProcesses = false
          this.searchProcesses = res.data.data.name
          this.processId = res.data.data.id
          setTimeout(() => {
            this.lock = false
            this.savePreFill()
            this.sendQuery()
          }, 50)
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    },
    goToLogsStep(processId, eventId, dateFrom, dateTill) {
      this.$router.push({ name: 'logs-steps', query: { processId, eventId, dateFrom: format(dateFrom, 'yyyy-MM-dd'), dateTill: format(dateTill, 'yyyy-MM-dd') } })
    },
    sendQuery(resetPage = true) {
      if (this.dateFrom && this.dateTill && !this.lock) {
        this.$emit('fetchLogs', { options: this.options, dateFrom: this.dateFrom, dateTill: this.dateTill, processId: this.processId, status: this.status, triggerId: this.triggerId, type: this.type, resetPage  })
        this.optionsForChart = { options: this.options, dateFrom: this.dateFrom, dateTill: this.dateTill, processId: this.processId, status: this.status, triggerId: this.triggerId, type: this.type  }
        this.savePreFill()
        this.tab = 0
        if (resetPage) this.options.page = 1
      }
    },
    searchProcessesFunction(val = '') {
      if (this.lock) return
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.err = err
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
